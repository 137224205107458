import React from "react";
import { FormHelperText, Grid, Button, FormControl, InputLabel, Select, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Colors } from "src/constants/colors.constants";

interface FileSelectProps {
  color?: "primary" | "secondary";
  label?: string | null;
  handleFileChange?: (file?: File) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  file?: File;
  url?: string;
  ariaLabel?: string;
  inputLabel?: string;
  endIcon?: JSX.Element;
  fullwidth?: boolean;
}

const FileSelect = React.memo(
  (props: FileSelectProps) => {
    const {
      color = "primary",
      label,
      file,
      url,
      handleFileChange,
      required,
      disabled,
      error,
      fullwidth,
      ariaLabel,
      inputLabel,
      endIcon,
    } = props;

    const inputFileRef = React.useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation();

    const noFileRequired = required && !file;

    return (
      <Grid container spacing={1} width="100%">
        <input
          style={{ display: "none" }}
          type="file"
          ref={inputFileRef}
          onChange={(e) => handleFileChange?.(e.target.files?.[0])}
        />
        <Grid item xs={12}>
          <FormControl
            sx={{
              position: "absolute",
            }}
          >
            <InputLabel
              id={inputLabel}
              sx={{
                color: noFileRequired ? "red" : "rgba(0, 0, 0, 0.87)",
                lineHeight: "1.4375em",
                fontSize: "12px",
                maxWidth: "100%",
                fontWeight: 400,
                position: "relative",
                left: 0,
                top: "-36px",
              }}
            >
              {inputLabel}
            </InputLabel>
            <Select
              labelId={inputLabel}
              disabled={true}
              sx={{
                display: "none",
              }}
            ></Select>
          </FormControl>
          <Button
            endIcon={endIcon}
            aria-label={ariaLabel}
            sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            fullWidth
            disabled={disabled}
            onClick={
              disabled
                ? undefined
                : () => {
                    // Check if the user has allowed access to the camera on mobile
                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                        navigator.mediaDevices
                          .getUserMedia({ video: true })
                          .then(() => {})
                          .catch(() => {
                            enqueueSnackbar(
                              "Pour utiliser cette fonctionnalité, veuillez autoriser l'accès à la caméra",
                              {
                                variant: "default",
                              }
                            );
                          });
                      }
                    }
                    inputFileRef.current?.click();
                  }
            }
          >
            <Typography
              sx={{
                fontSize: "12px",
              }}
              color={noFileRequired ? Colors.red : Colors.text}
            >
              {noFileRequired ? t("common.toBeCompleted") : label}
            </Typography>
            {required ? "*" : ""}
          </Button>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <FormHelperText error>{error}</FormHelperText>
          </Grid>
        )}
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.file === nextProps.file && prevProps.url === nextProps.url && prevProps.error === nextProps.error;
  }
);

export default FileSelect;
